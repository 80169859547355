/* custom chatbot styles */

.bpOpen {
  width: 100%;
}

@media (min-width: 768px) {
  .bpOpen {
    width: 425px !important;
  }
}

@media (min-width: 1024px) {
  .bpOpen {
    width: 30% !important;
    max-width: 525px !important;
  }
}

/* Because default modal of MUI has zIndex of 1300, and in case of login dialog appear, bot chat fab icon will be under dialog */
.bpFab {
  z-index: 1299 !important; 
}