.loading-spinner {
  animation: rotate 0.8s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

/* charts styles  */
#customer-license-health-chart .apexcharts-tooltip {
  background: #e9eaeb;
  color: #1b273c;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

#customer-license-health-chart .apexcharts-series {
  cursor: pointer;
}

#customer-license-usage-chart .apexcharts-tooltip, #customer-purchase-rate-chart .apexcharts-tooltip {
  background: #e9eaeb;
  color: #1b273c;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

#customer-license-usage-chart .apexcharts-series {
  cursor: pointer;
}

.custom-chart-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100px;
}

.custom-chart-tooltip-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.custom-chart-tooltip-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
